import React, { useState, useEffect } from "react";
import axios from "axios";
import "./service-provider-auth.css";
import GoogleLogo from "../Assets/googlelogo.png";
import logo from "../Assets/RAPSAPP.png";
import signupImage from "../Assets/login.webp";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useServiceProvider } from "../contexts/ServiceProviderContext";

const backendUrl = "https://rapsapp-3b673.uc.r.appspot.com";

function ServiceProviderAuth({ show, handleClose }) {
  const [isLogin, setIsLogin] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const { setServiceProvider } = useServiceProvider();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleFormSwitch = () => {
    setIsLogin(!isLogin);
    setIsForgotPassword(false);
  };

  const notifyError = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    const email = document.getElementById("providerLoginEmail").value;
    const password = document.getElementById("providerLoginPassword").value;

    if (!email || !password) {
      notifyError("Email and password are required.");
      return;
    }

    try {
      const response = await axios.post(`${backendUrl}/auth/sign_in`, {
        email,
        password,
      });
      const { data } = response;
      setServiceProvider({
        uid: data.uid,
        email: data.email,
        displayName: data.displayName,
      });
      // Redirect to more details form
      window.location.href = `/more-details?uid=${data.uid}`;
    } catch (error) {
      notifyError(
        error.response?.data?.message || "Login failed. Please try again."
      );
    }
  };

  const handleSignup = async (event) => {
    event.preventDefault();
    const name = document.getElementById("providerSignupName").value;
    const email = document.getElementById("providerSignupEmail").value;
    const password = document.getElementById("providerSignupPassword").value;

    if (!name || !email || !password) {
      notifyError("All fields are mandatory.");
      return;
    }

    try {
      const response = await axios.post(`${backendUrl}/auth/sign_up`, {
        name,
        email,
        password,
      });
      const { data } = response;
      setServiceProvider({
        uid: data.uid,
        email: data.email,
        displayName: data.displayName,
      });
      // Redirect to more details form
      window.location.href = `/more-details?uid=${data.uid}`;
    } catch (error) {
      notifyError(
        error.response?.data?.message || "Sign up failed. Please try again."
      );
    }
  };

  const handleForgotPassword = async (event) => {
    event.preventDefault();
    const email = document.getElementById("providerForgotPasswordEmail").value;

    if (!email) {
      notifyError("Email is required.");
      return;
    }

    try {
      await axios.post(`${backendUrl}/auth/new_otp`, { email });
      toast.success("Password reset email sent.");
      setIsForgotPassword(false);
    } catch (error) {
      notifyError(
        error.response?.data?.message || "Failed to send reset email."
      );
    }
  };

  const handleGoogleSignIn = () => {
    window.location.href = `${backendUrl}/auth/google`;
  };

  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    // Handle Google redirect result
    axios
      .get(`${backendUrl}/auth/google`)
      .then((response) => {
        const user = response.data;
        setServiceProvider({
          uid: user.uid,
          email: user.email,
          displayName: user.displayName,
        });
        window.location.href = `/more-details?uid=${user.uid}`;
      })
      .catch((error) => {
        if (error.response) {
          notifyError(error.response.data.message || "Google login failed.");
        }
      });
  }, [show]);

  if (!show) {
    return null;
  }

  return (
    <>
      <ToastContainer />
      <div className="service-provider-auth-overlay" onClick={handleClose}>
        <div
          className="service-provider-auth-modal"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="service-provider-auth-body">
            <div className="service-provider-auth-row">
              <div className="service-provider-auth-image-col">
                <img
                  src={signupImage}
                  className="service-provider-auth-image"
                  alt="Signup"
                />
              </div>
              <div className="service-provider-auth-form-col">
                <img
                  src={logo}
                  className="service-provider-auth-logo"
                  alt="Logo"
                />
                <h3>
                  {isLogin
                    ? "Welcome back! Glad to see you, Again!"
                    : "Complete your registration"}
                </h3>
                {isForgotPassword ? (
                  <form
                    style={{ width: "100%" }}
                    onSubmit={handleForgotPassword}
                  >
                    <div className="form-group">
                      <input
                        type="email"
                        id="providerForgotPasswordEmail"
                        placeholder="Enter email"
                      />
                    </div>
                    <button type="submit" className="btn-primary-nav">
                      Send Reset Link
                    </button>
                    <p className="mt-3">
                      <span
                        className="toggle-form"
                        onClick={() => setIsForgotPassword(false)}
                      >
                        Back to Login
                      </span>
                    </p>
                  </form>
                ) : isLogin ? (
                  <form style={{ width: "100%" }} onSubmit={handleLogin}>
                    <div className="form-group">
                      <input
                        type="email"
                        id="providerLoginEmail"
                        placeholder="Enter email"
                      />
                    </div>
                    <div className="password-input-container">
                      <input
                        type={showPassword ? "text" : "password"}
                        id="providerLoginPassword"
                        placeholder="Password"
                      />
                      <span
                        className="password-toggle-icon"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                      </span>
                    </div>
                    <button type="submit" className="btn-primary-nav">
                      Login
                    </button>
                    <p className="mt-3">
                      Forgot Password?{" "}
                      <span
                        className="toggle-form"
                        onClick={() => setIsForgotPassword(true)}
                      >
                        Click to Reset
                      </span>
                    </p>
                  </form>
                ) : (
                  <form style={{ width: "100%" }} onSubmit={handleSignup}>
                    <div className="form-group">
                      <input
                        type="text"
                        id="providerSignupName"
                        placeholder="Enter full name"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="email"
                        id="providerSignupEmail"
                        placeholder="Enter email"
                      />
                    </div>
                    <div className="password-input-container">
                      <input
                        type={showPassword ? "text" : "password"}
                        id="providerSignupPassword"
                        placeholder="Password"
                      />
                      <span
                        className="password-toggle-icon"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                      </span>
                    </div>
                    <button type="submit" className="btn-primary-nav">
                      Sign Up
                    </button>
                  </form>
                )}
                <div className="social-icons">
                  <div
                    style={{
                      border: "0.5px solid #E8ECF4",
                      borderRadius: "4px",
                      padding: "8px 12px",
                      cursor: "pointer",
                    }}
                    onClick={handleGoogleSignIn}
                  >
                    <img
                      src={GoogleLogo}
                      alt="Google Logo"
                      style={{ width: "32px" }}
                      className="social-icon"
                    />
                  </div>
                </div>
                <p className="text-center mt-3">
                  {isLogin ? "New User?" : "Already have an account?"}{" "}
                  <span className="toggle-form" onClick={handleFormSwitch}>
                    {isLogin ? "Sign Up as Provider" : "Sign In as Provider"}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceProviderAuth;
