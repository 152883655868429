import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import "./Footer.css";
import rapsapp2 from "../Assets/RAPSAPP2.png";
import ServiceProviderAuth from "../components/ServiceProviderAuth";

function Footer() {
  let date = new Date();
  let year = date.getFullYear();

  const [isServiceProviderAuthVisible, setIsServiceProviderAuthVisible] =
    useState(false);
  const navigate = useNavigate();

  const handleOpenServiceProviderAuth = () => {
    setIsServiceProviderAuthVisible(true);
  };

  const handleCloseServiceProviderAuth = () => {
    setIsServiceProviderAuthVisible(false);
  };

  return (
    <div className="footer">
      <div className="footer-section">
        <div className="footer-col-wide">
          <img src={rapsapp2} alt="Footer" className="footer-logo2" />
          <p>
            RAPS APP was founded on the belief that everyone deserves access to
            quality services without the long wait times. Our founders, a team
            of seasoned professionals from the tech and service industries, came
            together with a common goal: to revolutionize the way people access
            essential services.
          </p>
          <ul className="footer-icons">
            <li className="social-icons">
              <a
                href="https://github.com/soumyajit4419"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiFillGithub />
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://twitter.com/Soumyajit4419"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiOutlineTwitter />
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://www.linkedin.com/in/soumyajit4419/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedinIn />
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://www.instagram.com/soumyajit4419"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiFillInstagram />
              </a>
            </li>
          </ul>
        </div>
        <div className="footer-col">
          <h3>Navigation</h3>
          <ul className="footer-nav">
            <li>
              <Link to="/home">Home</Link>
            </li>
            <li>
              <Link to="/about">About Us</Link>
            </li>
            <li>
              <Link to="/services">Services</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
            <li>
              <Link onClick={handleOpenServiceProviderAuth}>
                Register as Service Provider
              </Link>
            </li>
          </ul>
        </div>
        <div className="footer-col">
          <h3>Resources</h3>
          <ul className="footer-nav">
            <li>
              <button
                onClick={() => navigate("/privacypolicy")}
                style={{
                  border: "none",
                  background: "none",
                  textDecoration: "none",
                  color: "black",
                  padding: "0px",
                  textAlign: "left",
                  cursor: "pointer",
                }}
              >
                Privacy Policy
              </button>
            </li>
            <li>
              <button
                onClick={() => navigate("/termsandconditions")}
                style={{
                  border: "none",
                  background: "none",
                  textDecoration: "none",
                  color: "black",
                  padding: "0px",
                  textAlign: "left",
                  cursor: "pointer",
                }}
              >
                Terms & Conditions
              </button>
            </li>
            <li>
              <button
                onClick={() => navigate("/returnpolicy")}
                style={{
                  border: "none",
                  background: "none",
                  textDecoration: "none",
                  color: "black",
                  padding: "0px",
                  textAlign: "left",
                  cursor: "pointer",
                }}
              >
                Return Policy
              </button>
            </li>
            <li>
              <Link to="#support">Support</Link>
            </li>
          </ul>
        </div>
        <div className="footer-col">
          <h2 style={{ fontWeight: "750", fontSize: "1.4em" }}>
            RapsApp Pvt. Ltd.
          </h2>
          <p>
            <strong>Phone:</strong> +91 7018401540
          </p>
          <p>
            <strong>Email:</strong> rapsappservice@gmail.com
          </p>
          <p>
            <strong>Address:</strong> House Number 67, Village Dhelu, P.O. &
            Tehsil Joginder Nagar Distt. Mandi Himachal Pradesh 175015
          </p>
        </div>
      </div>
      <div className="footer-copywright">
        <h3>
          Copyright © {year} RAPSAPP Private Limited. All Rights Reserved.
        </h3>
      </div>

      <ServiceProviderAuth
        show={isServiceProviderAuthVisible}
        handleClose={handleCloseServiceProviderAuth}
        setUserName={() => {}}
      />
    </div>
  );
}

export default Footer;
