import React, { useState } from "react";
import Carousel from "../home-carousel";
import optionimg1 from "../../Assets/1.webp";
import optionimg2 from "../../Assets/2.webp";
import optionimg3 from "../../Assets/8.webp";
import optionimg4 from "../../Assets/10.webp";
import optionimg5 from "../../Assets/4.webp";
import optionimg6 from "../../Assets/3.webp";
import optionimg7 from "../../Assets/4.webp";
import optionimg8 from "../../Assets/5.webp";
import optionimg9 from "../../Assets/6.webp";
import optionimg10 from "../../Assets/7.webp";
import optionimg11 from "../../Assets/9.webp";
import optionimg12 from "../../Assets/11.webp";
import optionimg14 from "../../Assets/10.webp";
import optionimg15 from "../../Assets/13.webp";
import optionimg16 from "../../Assets/12.webp";
import optionimg17 from "../../Assets/14.webp";
import optionimg18 from "../../Assets/15.webp";
import optionimg19 from "../../Assets/16.webp";
import optionimg20 from "../../Assets/17.webp";
import optionimg21 from "../../Assets/18.webp";
import optionimg22 from "../../Assets/19.webp";
import optionimg23 from "../../Assets/20.webp";
import homeoffer from "../../Assets/home_offer.png";
import hpimg from "../../Assets/hpimg.webp";
import CardCarousel from "../card-carousel";
import service1 from "../../Assets/mainservice1.webp";
import service2 from "../../Assets/mainservice2.webp";
import service3 from "../../Assets/mainservice3.webp";
import service4 from "../../Assets/mainservice4.webp";
import service5 from "../../Assets/mainservice5.webp";
import service6 from "../../Assets/mainservice6.webp";
import "./Home.css";
import "./Testimonials.css";
import review1 from "../../Assets/profile1.webp";
import review2 from "../../Assets/profile2.webp";
import review3 from "../../Assets/profile3.webp";
import review from "../../Assets/reviews.png";
import OfferCarousel from "../OfferCarousel";
function Home() {
  const [selectedOption, setSelectedOption] = useState(1);
  const renderComponent = () => {
    const handleImageClick = (option, rowIndex, colIndex) => {
      // Redirect to another page or perform an action
      console.log(
        `Redirecting to Option ${option} - Row ${rowIndex} - Col ${colIndex}`
      );
      // Example redirection
      // window.location.href = '/some-path';
    };

    switch (selectedOption) {
      case 1:
        return (
          <div className="option-content">
            <div className="row">
              <div className="column">
                <div className="image-container">
                  <img
                    src={optionimg1}
                    alt="Option1-Image1"
                    onClick={() => handleImageClick(1, 1, 1)}
                  />
                </div>
                <div className="image-container">
                  <img
                    src={optionimg2}
                    alt="Option1-Image2"
                    onClick={() => handleImageClick(1, 1, 2)}
                  />
                </div>
              </div>
              <div className="column single">
                <div className="image-container">
                  <img
                    src={optionimg3}
                    alt="Option1-Image3"
                    onClick={() => handleImageClick(1, 1, 3)}
                  />
                </div>
              </div>
              <div className="column">
                <div className="image-container">
                  <img
                    src={optionimg4}
                    alt="Option1-Image4"
                    onClick={() => handleImageClick(1, 1, 4)}
                  />
                </div>
                <div className="image-container">
                  <img
                    src={optionimg5}
                    alt="Option1-Image5"
                    onClick={() => handleImageClick(1, 1, 5)}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="option-content">
            <div className="row">
              <div className="column">
                <div className="image-container">
                  <img
                    src={optionimg6}
                    alt="Option1-Image1"
                    onClick={() => handleImageClick(1, 1, 1)}
                  />
                </div>
                <div className="image-container">
                  <img
                    src={optionimg7}
                    alt="Option1-Image2"
                    onClick={() => handleImageClick(1, 1, 2)}
                  />
                </div>
              </div>
              <div className="column single">
                <div className="image-container">
                  <img
                    src={optionimg8}
                    alt="Option1-Image3"
                    onClick={() => handleImageClick(1, 1, 3)}
                  />
                </div>
              </div>
              <div className="column">
                <div className="image-container">
                  <img
                    src={optionimg9}
                    alt="Option1-Image4"
                    onClick={() => handleImageClick(1, 1, 4)}
                  />
                </div>
                <div className="image-container">
                  <img
                    src={optionimg10}
                    alt="Option1-Image5"
                    onClick={() => handleImageClick(1, 1, 5)}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="option-content">
            <div className="row">
              <div className="column">
                <div className="image-container">
                  <img
                    src={optionimg11}
                    alt="Option1-Image1"
                    onClick={() => handleImageClick(1, 1, 1)}
                  />
                </div>
                <div className="image-container">
                  <img
                    src={optionimg3}
                    alt="Option1-Image2"
                    onClick={() => handleImageClick(1, 1, 2)}
                  />
                </div>
              </div>
              <div className="column single">
                <div className="image-container">
                  <img
                    src={optionimg1}
                    alt="Option1-Image3"
                    onClick={() => handleImageClick(1, 1, 3)}
                  />
                </div>
              </div>
              <div className="column">
                <div className="image-container">
                  <img
                    src={optionimg2}
                    alt="Option1-Image4"
                    onClick={() => handleImageClick(1, 1, 4)}
                  />
                </div>
                <div className="image-container">
                  <img
                    src={optionimg12}
                    alt="Option1-Image5"
                    onClick={() => handleImageClick(1, 1, 5)}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      case 4:
        return (
          <div className="option-content">
            <div className="row">
              <div className="column">
                <div className="image-container">
                  <img
                    src={optionimg14}
                    alt="Option1-Image1"
                    onClick={() => handleImageClick(1, 1, 1)}
                  />
                </div>
                <div className="image-container">
                  <img
                    src={optionimg15}
                    alt="Option1-Image2"
                    onClick={() => handleImageClick(1, 1, 2)}
                  />
                </div>
              </div>
              <div className="column single">
                <div className="image-container">
                  <img
                    src={optionimg16}
                    alt="Option1-Image3"
                    onClick={() => handleImageClick(1, 1, 3)}
                  />
                </div>
              </div>
              <div className="column">
                <div className="image-container">
                  <img
                    src={optionimg17}
                    alt="Option1-Image4"
                    onClick={() => handleImageClick(1, 1, 4)}
                  />
                </div>
                <div className="image-container">
                  <img
                    src={optionimg18}
                    alt="Option1-Image5"
                    onClick={() => handleImageClick(1, 1, 5)}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      case 5:
        return (
          <div className="option-content">
            <div className="row">
              <div className="column">
                <div className="image-container">
                  <img
                    src={optionimg19}
                    alt="Option1-Image1"
                    onClick={() => handleImageClick(1, 1, 1)}
                  />
                </div>
                <div className="image-container">
                  <img
                    src={optionimg20}
                    alt="Option1-Image2"
                    onClick={() => handleImageClick(1, 1, 2)}
                  />
                </div>
              </div>
              <div className="column single">
                <div className="image-container">
                  <img
                    src={optionimg21}
                    alt="Option1-Image3"
                    onClick={() => handleImageClick(1, 1, 3)}
                  />
                </div>
              </div>
              <div className="column">
                <div className="image-container">
                  <img
                    src={optionimg22}
                    alt="Option1-Image4"
                    onClick={() => handleImageClick(1, 1, 4)}
                  />
                </div>
                <div className="image-container">
                  <img
                    src={optionimg23}
                    alt="Option1-Image5"
                    onClick={() => handleImageClick(1, 1, 5)}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };
  const cards = [
    {
      image: service1,
      heading: "Sofa Cleaning",
      description: "Get a Spotless Sofa",
      btn1Text: "Schedule Service",
      btn2Text: "Buy Now",
      btn1Action: () => alert("Button 1 clicked on Card 1"),
      btn2Action: () => alert("Button 2 clicked on Card 1"),
    },
    {
      image: service2,
      heading: "TV Repair",
      description: "Fix Your TV Today",
      btn1Text: "Schedule Service",
      btn2Text: "Buy Now",
      btn1Action: () => alert("Button 1 clicked on Card 1"),
      btn2Action: () => alert("Button 2 clicked on Card 1"),
    },
    {
      image: service5,
      heading: "House Cleaning",
      description: "Refresh Your Home",
      btn1Text: "Schedule Service",
      btn2Text: "Buy Now",
      btn1Action: () => alert("Button 1 clicked on Card 1"),
      btn2Action: () => alert("Button 2 clicked on Card 1"),
    },
    {
      image: service4,
      heading: "Refrigerator Repair",
      description: "Schedule a Repair Now",
      btn1Text: "Schedule Service",
      btn2Text: "Buy Now",
      btn1Action: () => alert("Button 1 clicked on Card 1"),
      btn2Action: () => alert("Button 2 clicked on Card 1"),
    },
    {
      image: service6,
      heading: "Interior Decoration",
      description: "Design Your Dream Home",
      btn1Text: "Schedule Service",
      btn2Text: "Buy Now",
      btn1Action: () => alert("Button 1 clicked on Card 1"),
      btn2Action: () => alert("Button 2 clicked on Card 1"),
    },
    {
      image: service3,
      heading: "W Machine Repair",
      description: "Restore Your Washing Machine",
      btn1Text: "Schedule Service",
      btn2Text: "Buy Now",
      btn1Action: () => alert("Button 1 clicked on Card 1"),
      btn2Action: () => alert("Button 2 clicked on Card 1"),
    },
    {
      image: service4,
      heading: "Refrigerator Repair",
      description: "Schedule a Repair Now",
      btn1Text: "Schedule Service",
      btn2Text: "Buy Now",
      btn1Action: () => alert("Button 1 clicked on Card 1"),
      btn2Action: () => alert("Button 2 clicked on Card 1"),
    },
    {
      image: service6,
      heading: "Interior Decoration",
      description: "Design Your Dream Home",
      btn1Text: "Schedule Service",
      btn2Text: "Buy Now",
      btn1Action: () => alert("Button 1 clicked on Card 1"),
      btn2Action: () => alert("Button 2 clicked on Card 1"),
    },
    {
      image: service3,
      heading: "W Machine Repair",
      description: "Restore Your Washing Machine",
      btn1Text: "Schedule Service",
      btn2Text: "Buy Now",
      btn1Action: () => alert("Button 1 clicked on Card 1"),
      btn2Action: () => alert("Button 2 clicked on Card 1"),
    },
  ];
  const card2s = [
    { heading: "6+", description: "Cities we are in" },
    { heading: "900+", description: "Service providers we have" },
    { heading: "50+", description: "Services are offering" },
    { heading: "100+", description: "Services provided till now" },
  ];
  const TestimonialCard = ({
    heading,
    image,
    description,
    additionalImage,
    name,
    location,
    rating,
  }) => {
    // Function to generate star icons based on rating
    const renderStars = () => {
      const stars = [];
      const ratingValue = parseFloat(rating); // Convert rating to a float number
      const roundedRating = Math.round(ratingValue); // Round to the nearest integer

      // Loop to create star icons
      for (let i = 0; i < roundedRating; i++) {
        stars.push(<span key={i}>&#9733;</span>); // Unicode star character
      }

      return stars;
    };

    return (
      <div className="testimonial-card">
        <div className="card-section">
          <h4>{heading}</h4>
          <img src={image} alt={heading} className="card-images" />
        </div>
        <div className="card-section">
          <p style={{ alignItems: "justify" }}>{description}</p>
        </div>
        <div className="card-section">
          <img
            src={additionalImage}
            alt={heading}
            className="additional-image"
          />
          <div className="card-info">
            <p>
              <strong>{name}</strong>
            </p>
            <p>{location}</p>
            <div className="rating">{renderStars()}</div>{" "}
            {/* Render stars here */}
          </div>
        </div>
      </div>
    );
  };
  const testimonials = [
    {
      heading: "Home Cleaning Service",
      image: review,
      description:
        "I booked a home cleaning service through RapsApp, and I couldn't be happier with the results! The team was professional, thorough, and left my home sparkling clean. I highly recommend their services!",
      additionalImage: review1,
      name: "Dr. Ankush",
      location: "Hamirpur",
      rating: "5/5",
    },
    {
      heading: "Beauty Services",
      image: review,
      description:
        "I had a wonderful experience with the beauty services from RapsApp. The beautician was punctual, professional, and did an amazing job. I felt pampered and beautiful, all from the comfort of my home!",
      additionalImage: review3,
      name: "Mrs. Neha Sharma",
      location: "Sundernagar",
      rating: "5/5",
    },
    {
      heading: "Electrical Services",
      image: review,
      description:
        "Excellent service from start to finish! The electrician from RapsApp was efficient, courteous, and fixed our electrical issues in no time. Highly recommended for any electrical work!",
      additionalImage: review2,
      name: "Mr. Rohit",
      location: "Kangra",
      rating: "5/5",
    },
  ];

  return (
    <>
      <section className="home-section">
        <Carousel />
      </section>
      <section
        className="home-services"
        style={{ width: "80%", margin: "120px auto 120px auto" }}
      >
        <h2>From Repairs to Renovations – We Do It All!</h2>

        <div className="options">
          <button
            className={selectedOption === 1 ? "active" : ""}
            onClick={() => setSelectedOption(1)}
            style={{ textDecoration: "none" }}
          >
            Popular Picks
          </button>
          <button
            className={selectedOption === 2 ? "active" : ""}
            onClick={() => setSelectedOption(2)}
            style={{ textDecoration: "none" }}
          >
            Salon
          </button>
          <button
            className={selectedOption === 3 ? "active" : ""}
            onClick={() => setSelectedOption(3)}
            style={{ textDecoration: "none" }}
          >
            Repair
          </button>
          <button
            className={selectedOption === 4 ? "active" : ""}
            onClick={() => setSelectedOption(4)}
            style={{ textDecoration: "none" }}
          >
            Schedule
          </button>
          <button
            className={selectedOption === 5 ? "active" : ""}
            onClick={() => setSelectedOption(5)}
            style={{ textDecoration: "none" }}
          >
            Travel
          </button>
        </div>
        <div className="componentContainer">{renderComponent()}</div>
      </section>
      <section className="home-offer">
        <OfferCarousel />
      </section>
      <section
        className="home-services"
        style={{ width: "80%", margin: "120px auto 120px auto" }}
      >
        <h2>Quality Service, Guaranteed Satisfaction!</h2>
        <CardCarousel cards={cards} />
      </section>
      <section
        className="section-container"
        style={{ width: "86vw", margin: "0 auto" }}
      >
        <div className="left-div">
          <img src={hpimg} alt="Placeholder" />
        </div>
        <div className="right-div">
          {card2s.map((card2, index) => (
            <div className="card2" key={index}>
              <h3>{card2.heading}</h3>
              <p>{card2.description}</p>
            </div>
          ))}
        </div>
      </section>
      <section
        className="home-services"
        style={{ width: "80%", margin: "120px auto 120px auto" }}
      >
        {" "}
        <h2>What our customers have to say</h2>
        <div className="testimonials-container">
          {testimonials.map((testimonial, index) => (
            <TestimonialCard key={index} {...testimonial} />
          ))}
        </div>
      </section>
    </>
  );
}

export default Home;
