import React, { useState, useRef } from "react";
import { useAuth } from "../../contexts/AuthContext";
import "./ProductPage.css";
import bigimage from "../../Assets/brideposter1.jpg";
import smallimage1 from "../../Assets/brideposter2.jpg";
import smallimage2 from "../../Assets/brideposter3.jpg";
import CardCarousel from "../card-carousel";
import service from "../../Assets/service.png";
import AuthModal from "../AuthModal"; // Ensure the path is correct
import "./PaymentGatewayModal.css";
function ProductPage() {
  const [currentOption, setCurrentOption] = useState(1);
  const [selectedOptions, setSelectedOptions] = useState({
    option1: "",
    option2: "",
    option3: "",
    option4: "",
  });
  const [selectedAddOn, setSelectedAddOn] = useState(null);
  const [inputFields, setInputFields] = useState({
    date: "",
    time: "",
    address: "",
  });
  const [showAuthModal, setShowAuthModal] = useState(false);
  const optionsRef = useRef([]);

  const { currentUser } = useAuth();
  const isLoggedIn = !!currentUser;

  const handleOptionSelect = (value, option) => {
    setSelectedOptions({ ...selectedOptions, [option]: value });
    if (currentOption < 4) {
      setCurrentOption(currentOption + 1);
    }
    optionsRef.current[currentOption]?.scrollIntoView({ behavior: "smooth" });
  };

  const handleAddOnSelect = (addOn) => {
    setSelectedAddOn(addOn);
    if (currentOption < 4) {
      setCurrentOption(currentOption + 1);
    }
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setInputFields({ ...inputFields, [id]: value });
  };

  const isNextButtonEnabled = () => {
    switch (currentOption) {
      case 1:
        return inputFields.date && inputFields.time;
      case 2:
        return inputFields.address;
      case 3:
        return selectedAddOn !== null;
      default:
        return false;
    }
  };

  const handleNextStep = () => {
    if (isNextButtonEnabled()) {
      if (currentOption < 4) {
        setCurrentOption(currentOption + 1);
        optionsRef.current[currentOption]?.scrollIntoView({
          behavior: "smooth",
        });
      }
    }
  };

  const handleAuthModalShow = () => {
    console.log("Showing auth modal"); // Debugging statement
    window.scrollTo({ top: 0, behavior: "smooth" });
    setShowAuthModal(true);
  };

  const handleLogin = () => {
    handleAuthModalShow();
  };

  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const handlePayment = () => {
    setShowPaymentModal(true);
  };

  const closePaymentModal = () => {
    setShowPaymentModal(false);
  };
  function calculateTotalCost() {
    let total = 299;
    if (selectedAddOn === "Pedicure") total += 99;
    if (selectedAddOn === "Bleach") total += 79;
    if (selectedAddOn === "No Add-On") total += 0;
    return `₹${total}`;
  }

  return (
    <>
      <div className="product-page">
        <div className="product-info">
          <div className="product-title">
            <div className="product-name">
              <h1>Bridal Makeup</h1>
              <p>
                Get stunning bridal makeup from just ₹299 and shine on your
                special day!
              </p>
            </div>
            <div className="product-rating">
              <p>★★★★☆</p>
              <p style={{ transform: "translateY(-12px)" }}>reviews</p>
            </div>
          </div>

          <div className="image-section">
            <img src={bigimage} alt="Main" className="main-image" />
            <div className="small-images">
              <img src={smallimage1} alt="Small 1" className="small-image" />
              <img src={smallimage2} alt="Small 2" className="small-image" />
            </div>
          </div>
        </div>

        <div className="product-options">
          <div
            className="option-header"
            ref={(el) => (optionsRef.current[0] = el)}
            style={{ display: currentOption === 1 ? "block" : "none" }}
          >
            <h2>Select Date and Time</h2>
            <hr />
            <label className="product-input-label">
              Date
              <input
                type="date"
                id="date"
                value={inputFields.date}
                onChange={handleInputChange}
              />
            </label>
            <label className="product-input-label">
              Time
              <input
                type="time"
                id="time"
                value={inputFields.time}
                onChange={handleInputChange}
              />
            </label>
            <button
              className="next-button"
              onClick={handleNextStep}
              disabled={!isNextButtonEnabled()}
            >
              Next
            </button>
          </div>
          <div
            className="option-header"
            ref={(el) => (optionsRef.current[1] = el)}
            style={{ display: currentOption === 2 ? "block" : "none" }}
          >
            <h2>Enter Address</h2>
            <hr />
            <label className="product-input-label">
              Address
              <textarea
                id="address"
                value={inputFields.address}
                onChange={handleInputChange}
                placeholder="Enter your address"
              />
            </label>
            <button
              className="next-button"
              onClick={handleNextStep}
              disabled={!isNextButtonEnabled()}
            >
              Next
            </button>
          </div>
          <div
            className="option-header"
            ref={(el) => (optionsRef.current[2] = el)}
            style={{ display: currentOption === 3 ? "block" : "none" }}
          >
            <h2>Select Add-Ons</h2>
            <hr />
            <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
              <button
                className="addon-option"
                onClick={() => handleAddOnSelect("Pedicure")}
              >
                Pedicure @99 only
              </button>
              <button
                className="addon-option"
                onClick={() => handleAddOnSelect("Bleach")}
              >
                Bleach @79 only
              </button>
            </div>
            <button
              className="addon-option"
              onClick={() => handleAddOnSelect("No Add-On")}
            >
              Skip Add-On
            </button>
          </div>
          <div
            className="option-header"
            ref={(el) => (optionsRef.current[3] = el)}
            style={{ display: currentOption === 4 ? "block" : "none" }}
          >
            <h2>Review & Confirm</h2>
            <hr />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <p className="addon-box">
                <strong>Add-Ons </strong> {selectedAddOn}
              </p>
              <p className="addon-box">
                <strong>Total Cost </strong> {calculateTotalCost()}
              </p>
            </div>
            {!isLoggedIn ? (
              <button className="next-button" onClick={handleLogin}>
                Login to Make Payment
              </button>
            ) : (
              <button className="next-button" onClick={handlePayment}>
                Make Payment
              </button>
            )}
          </div>
        </div>

        {/* Render the AuthModal component */}
        {showAuthModal && (
          <AuthModal
            show={showAuthModal}
            handleClose={() => setShowAuthModal(false)}
            setUserName={(name) => {
              // Handle the user name setting here if needed
            }}
          />
        )}
        {showPaymentModal && (
          <div className="payment-modal-overlay" onClick={closePaymentModal}>
            <div
              className="payment-modal-content"
              onClick={(e) => e.stopPropagation()}
            >
              <h2>Payment Gateway Coming Soon</h2>
              <button
                className="close-payment-modal-btn"
                onClick={closePaymentModal}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default ProductPage;
