import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import servicesData from "./serviceData.json";
import ParticularCard from "./particular-card";
import service21 from "../Assets/mainservice1.webp"; // Ensure this path is correct
import "./particularservice.css";

function ParticularService() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const categoryName = queryParams.get("category"); // Ensure this matches exactly

  const [selected, setSelected] = useState("female");
  const [subcategories, setSubcategories] = useState([]); // Ensure this is initialized as an array
  const [header, setHeader] = useState(""); // State for header text

  useEffect(() => {
    // Find category based on selected category
    const category = servicesData.categories.find(
      (cat) => cat.name.toLowerCase() === categoryName.toLowerCase() // Match names case-insensitively
    );

    if (category) {
      // Set header text
      setHeader(category.head);
      // Set subcategories
      const subcats = category.subcategories;
      console.log("Subcategories:", subcats); // Debugging line
      setSubcategories(subcats);
    } else {
      console.error("No category found with the name:", categoryName);
      setSubcategories([]); // Ensure `subcategories` is always defined
    }
  }, [categoryName]);

  const handleClick = (value) => {
    setSelected(value);
  };

  const handleScheduleClick = (serviceTitle) => {
    navigate("/personal", { state: { selectedService: serviceTitle } });
  };

  // Map subcategories to cards
  const cards = subcategories.map((sub) => ({
    image: service21, // Ensure this path is correct
    name: sub.name,
    heading: `Services Starting from ${
      sub.services[0].description.split(" ")[2]
    }`,
    // heading2: "See More",
    btn1Text: "Schedule Service",
    btn1Action: () => handleScheduleClick(sub.name),
    btn2Action: () => alert("Button 2 clicked on " + sub.name),
  }));

  console.log("Cards Data:", cards); // Debugging line

  return (
    <div>
      <section style={{ width: "86vw", margin: "0 auto", marginTop: "160px" }}>
        <div className="ps-header">
          <div className="ps-head">
            <h2>{header || "Default Category Name"}</h2>{" "}
            {/* Use header state */}
            <p>Your Go-To Platform for Prioritized Services</p>
          </div>
          <div className="toggle-container">
            <button
              className={`toggle-button ${
                selected === "female" ? "active" : ""
              }`}
              onClick={() => handleClick("female")}
            >
              Female
            </button>
            <button
              className={`toggle-button ${selected === "male" ? "active" : ""}`}
              onClick={() => handleClick("male")}
            >
              Male
            </button>
          </div>
        </div>
        <hr />
        <ParticularCard cards={cards} />
      </section>
    </div>
  );
}

export default ParticularService;
